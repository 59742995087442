import React, { FC } from 'react';
import { FormControl, FormGroup } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import CheckboxInput from '@/components/forms/CheckboxInput';
import SwitchInput from '@/components/forms/SwitchInput';
import Styles from '@/assets/js/Styles';

export const settingsAreaStyle = {
  width: '100%',
  backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR,
  padding: 2,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  gap: 2,
};

export const SCHOOL_FILTERING_SORTING_FIELDS = [
  'Grade',
  'Subject',
  'Museum',
  'DigitalResourcesVisitTheMuseums',
  'RelevanceUpdated',
  'AlphabeticalOrder',
];

const ToggleSortingFilteringSettings: FC<{ tComp: any; blockPath: BlockPath }> = ({ tComp, blockPath }) => {
  const userFilteringSorting = useWatch({ name: `${blockPath}.userFilteringSorting` });

  return (
    <FormControl sx={settingsAreaStyle}>
      <div>
        <SwitchInput path={`${blockPath}.userFilteringSorting`} label={tComp('DMBlock.AddFilteringSortingForUsers')} />
      </div>
      <FormGroup row>
        {SCHOOL_FILTERING_SORTING_FIELDS.map((field) => (
          <CheckboxInput
            key={field}
            path={`${blockPath}.show${field}`}
            label={tComp(`DMBlock.EndUserFilteringSorting.${field}`)}
            fullWidth={false}
            disabled={!userFilteringSorting}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default ToggleSortingFilteringSettings;
