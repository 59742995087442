import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, Typography } from '@mui/material';
import { noImgStyle } from '@/editor/PageEditor/CommonSectionContent';
import Container from '../../components/Container';
import { Page } from '../../declarations/models/Page';
import TextInput from '../../components/forms/TextInput';
import { useEditorModel } from '../lib/components/EditorDataProvider';
import SwitchInput from '../../components/forms/SwitchInput';
import { ArticleTitleBlock } from '../../declarations/models/blocks/ArticleTitleBlock';
import { BlockSpecificPage } from '../lib/declarations/BlockSpecificPage';
import { SectionType } from '../../declarations/models/SectionType';
import { BlockType } from '../../declarations/models/BlockType';
import { BlockPath } from './CurrentBlockProvider';
import { SectionPath } from './CurrentSectionProvider';
import FinderButton, { FinderType } from '../../components/Finder/FinderButton';
import { prepareMediaItemAsDMMediaModel, prepareMediaItemAsM24MediaModel } from '../../utils/MediaUtils';
import ImageWithFocusPointSelector from '../../components/ImageWithFocusPointSelector';
import DeleteButton from '../../components/DeleteButton';
import { GenericMedia } from '../../declarations/GenericMedia';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import { DMMediaModel } from '../../declarations/models/DMMediaModel';
import { getSubCategories, MainCategory, SubCategory } from '../../declarations/Category';

export const PageMetadata: FC<{
  isReordering?: boolean;
}> = ({ isReordering = false }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const page = useEditorModel<Page>();

  const titleSectionIndex = page?.content?.sections
    ? page?.content?.sections?.findIndex((s) => s?.type === SectionType.ARTICLE_HEAD)
    : 0;
  const articleTitleBlockIndex =
    page.content?.sections?.[titleSectionIndex]?.blocks?.findIndex((b) => b?.type === BlockType.ARTICLE_TITLE) || 0;
  const titleSectionPath: SectionPath = `content.sections.${titleSectionIndex}` as SectionPath;
  const articleTitleBlockPath: BlockPath = `${titleSectionPath}.blocks.${articleTitleBlockIndex}` as BlockPath;

  const syncPageDescription = useWatch<BlockSpecificPage<ArticleTitleBlock>, `${BlockPath}.syncPageDescription`>({
    name: `${articleTitleBlockPath}.syncPageDescription`,
  });
  const [pageDescriptionFieldKey, setPageDescriptionFieldKey] = React.useState<number>(0);
  const articleBlockDescriptionValue = useWatch<BlockSpecificPage<ArticleTitleBlock>, `${BlockPath}.leadtext`>({
    name: `${articleTitleBlockPath}.leadtext`,
  });
  const {
    field: { onChange: onChangePageDescription },
  } = useController<Page, 'description'>({
    name: 'description',
  });
  useEffect(() => {
    if (articleBlockDescriptionValue && syncPageDescription) {
      onChangePageDescription(articleBlockDescriptionValue);
      setPageDescriptionFieldKey((prev) => prev + 1);
    }
  }, [syncPageDescription, articleBlockDescriptionValue, onChangePageDescription]);

  const {
    field: { value: image, onChange: onChangeImage },
  } = useController<Page, 'image'>({ name: `image` });

  const {
    field: { value: mainCategory, onChange: onChangeMainCategory },
  } = useController<Page, 'main_category'>({ name: `main_category` });

  const {
    field: { value: subCategories, onChange: onChangeSubCategories },
  } = useController<Page, 'sub_categories'>({ name: 'sub_categories' });

  const handleChangeMainCategory = (v: MainCategory) => {
    onChangeSubCategories([]);
    onChangeMainCategory(v);
  };

  const handleSubCategoryChecked = (checked: boolean, category: SubCategory) => {
    const tempArr = subCategories ?? [];
    const i = tempArr?.indexOf(category);
    if (checked && i < 0) onChangeSubCategories([...tempArr, category]);
    else if (!checked && i >= 0) onChangeSubCategories([...tempArr.slice(0, i), ...tempArr.slice(i + 1)]);
  };

  const {
    field: { value: focus, onChange: onChangeFocusPoint },
  } = useController<Page, 'image_focus_point'>({ name: 'image_focus_point' });

  const handleFocusPointChanged = (focusPoint: { x: string; y: string }) => {
    onChangeFocusPoint({
      ...focusPoint,
      x: focusPoint.x,
      y: focusPoint.y,
    });
  };

  const handleRemoveImage = () => {
    onChangeImage(null);
    onChangeFocusPoint(null);
  };

  const handleChangeM24Image = (item: GenericMedia<M24MediaModel>) => {
    onChangeImage(prepareMediaItemAsM24MediaModel(item.source));
    onChangeFocusPoint(null);
  };

  const handleChangeDMImage = (item: GenericMedia<DMMediaModel>) => {
    onChangeImage(prepareMediaItemAsDMMediaModel(item.source, item.url));
    onChangeFocusPoint(null);
  };

  return (
    <Container gap={2} column top left fullWidth>
      <Container gap={2} column fullWidth left>
        <Typography fontWeight='bold'>{tComp('PageMetadata.pageMainImage')}:</Typography>
        <Container fullWidth>
          {image && (
            <Container column left gap={0}>
              <ImageWithFocusPointSelector
                src={`${image?.url || ''}`}
                x={page?.image_focus_point?.x}
                y={page?.image_focus_point?.y}
                onFocusPointChanged={handleFocusPointChanged}
              />
              <DeleteButton onConfirm={handleRemoveImage} deleteLabel='remove' iconColor='secondary' />
            </Container>
          )}
          {!image && <div style={noImgStyle}>Ingen bilde valgt</div>}
          <Container fullWidth column gap={2} top left px={6}>
            <Typography color='textSecondary' sx={{ wordBreak: 'break-word' }}>
              {tComp('PageMetadata.mainImageDescription')}
            </Typography>
            <Typography color='textSecondary' sx={{ wordBreak: 'break-word' }}>
              {tComp('PageMetadata.mainImageMissingInfotext')}
            </Typography>
          </Container>
        </Container>
        <Container column left>
          <Typography fontWeight='bold'>{tComp('PageMetadata.selectImageHeading')}</Typography>
          <Container>
            <FinderButton
              type={FinderType.M24}
              finderProps={{
                onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
                  items.forEach((item) => handleChangeM24Image(item)),
              }}
              multiSelect={false}
            />
            <FinderButton
              type={FinderType.DM}
              finderProps={{
                onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
                  items.forEach((item) => handleChangeDMImage(item)),
              }}
              multiSelect={false}
            />
          </Container>
        </Container>
      </Container>
      <Container gap={2} column fullWidth>
        <TextInput<Page>
          key={pageDescriptionFieldKey}
          path='description'
          label={tComp('PageMetadata.description')}
          defaultValue=''
          multiline
          disabled={syncPageDescription}
        />

        {/* This component will try to update the form state based on an index. If we are in the middle of reordering, */}
        {/* that index will be out of sync for a short while, and cause update the incorrect section/block. */}
        {!isReordering && (
          <SwitchInput<BlockSpecificPage<ArticleTitleBlock>>
            path={`${articleTitleBlockPath}.syncPageDescription`}
            label={tComp('PageMetadata.syncPageDescription')}
            disabled={titleSectionIndex === undefined || titleSectionIndex < 0}
          />
        )}
      </Container>
      <FormControl>
        <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6) !important' }}>{tComp('PageMetadata.MainCategoryLabel')}</FormLabel>
        <FormGroup row>
          {Object.values(MainCategory).map((c) => (
            <FormControlLabel
              key={c}
              control={
                <Radio onChange={() => handleChangeMainCategory(c)} checked={c ? c === mainCategory : !mainCategory} />
              }
              label={tCommon(`MainCategory.${c}`)}
            />
          ))}
        </FormGroup>
      </FormControl>
      {(mainCategory === MainCategory.EVENT ||
        mainCategory === MainCategory.EXHIBITION ||
        mainCategory === MainCategory.INFORMATION ||
        mainCategory === MainCategory.KNOWLEDGE) && (
        <FormControl>
          <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6) !important' }}>
            {tComp('PageMetadata.SubCategoriesLabel')}
          </FormLabel>
          <FormGroup row>
            {getSubCategories(mainCategory).map((c) => (
              <FormControlLabel
                key={c}
                control={
                  <Checkbox
                    onChange={(e) => handleSubCategoryChecked(e.target.checked, c)}
                    checked={(subCategories ?? []).includes(c)}
                  />
                }
                label={tCommon(`SubCategory.${c}`)}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      <Container gap={2} column fullWidth>
        <TextInput<Page>
          path='content.metadescription'
          label={tComp('PageMetadata.metadescription')}
          defaultValue=''
          multiline
        />
        <TextInput<Page>
          path='content.metatitle'
          label={tComp('PageMetadata.metaTitle')}
          defaultValue=''
          placeholder={`${page.title}`}
        />
      </Container>
    </Container>
  );
};

export default PageMetadata;
