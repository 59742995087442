import React, { useEffect } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { CardModuleBlock, CardModuleView } from '../../../declarations/models/blocks/CardModuleBlock';
import { useApi } from '../../../hooks/useApi';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import LinksPreview from './LinksPreview';
import { LinkStyle } from '../../../declarations/models/LinkStyle';
import ImagePreview from '../../../components/ImagePreview';
import { BlockType } from '../../../declarations/models/BlockType';
import { Card } from '../../../declarations/models/Card';
import { Person } from '../../../declarations/models/Person';

function sortCardItemsAlphabetically(
  manualCards: Array<(Card | Person) & { local?: { title?: string; description?: string } }>,
) {
  manualCards?.sort((a, b) => {
    const titleA = (a?.local?.title || a?.title || '').toLowerCase();
    const titleB = (b?.local?.title || b?.title || '').toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
  return manualCards;
}

export const CardModuleBlockPreview: BlockPreviewComponent = () => {
  const { state } = useStore();
  const { selectedSite, selectedSiteLanguage } = state;
  const { block } = useCurrentBlock();
  const currentBlock = block as CardModuleBlock;

  const [cardSearchResult, , reloadCardSearchResult] = useApi(
    () =>
      Api.getTaggedCardSearchResult(selectedSite?.id || 0, {
        categories: currentBlock?.categories,
        tags: currentBlock?.tags,
        rows: currentBlock?.rows || '20',
        order: currentBlock?.order,
        order_by: currentBlock?.orderBy,
        site_ids: currentBlock?.site_ids,
        entity_type: 'blocks',
        entity_subtype: currentBlock?.entity_subtype,
        page_id: currentBlock?.searchFromPage?.id,
        languages: selectedSiteLanguage || 'no',
      }),
    [],
  );

  let manualCards = currentBlock?.items;

  if (currentBlock?.orderBy === 'title') {
    sortCardItemsAlphabetically(manualCards);
  }

  if (currentBlock?.reverseOrder && currentBlock?.groupBy !== 'alpha') {
    manualCards = [...manualCards].reverse();
  }

  let hasCommonSkin = false;

  if (currentBlock?.version === '2' || (currentBlock?.skin && currentBlock.skin !== 'skin')) {
    hasCommonSkin = true;
  }

  // const cards = manualCards?.concat(filteredCardSearchResult);

  useEffect(() => {
    reloadCardSearchResult();
  }, [
    currentBlock?.categories,
    currentBlock?.tags,
    currentBlock?.rows,
    currentBlock?.order,
    currentBlock?.orderBy,
    currentBlock?.site_ids,
    reloadCardSearchResult,
  ]);

  const rows = parseInt(currentBlock?.rows || '20', 10);

  return (
    <>
      <div className={`article__grid alignmodify--${currentBlock?.view}`}>
        <section className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          {currentBlock?.body && <p>{currentBlock?.body}</p>}
        </section>
      </div>
      {currentBlock?.includeListSearch && (
        <div
          className={`article__grid ${
            currentBlock?.view?.split('-')?.length === 2 ? `w--${currentBlock?.view?.split('-')[1]}` : ''
          }`}>
          <section className='article__bodytext'>
            <div className='form' style={{ marginBottom: '20px' }}>
              <div className='form__label'>{currentBlock?.listSearchText}</div>
              <div className='form__input input--icon'>
                <input type='search' disabled />
                <i className='input__icon i-nav-search'>
                  <MaterialSymbol name='search' />
                </i>
              </div>
            </div>
          </section>
        </div>
      )}
      <div
        className={`section__grid ${
          currentBlock?.view?.split('-')?.length === 2 ? `w--${currentBlock?.view?.split('-')[1]}` : ''
        }`}>
        <section
          className={`row ${
            currentBlock?.view?.split('-')?.length === 2 ? `w--${currentBlock?.view?.split('-')[1]}` : ''
          } ${currentBlock?.view === CardModuleView.ARTICLES ? 'row--articles' : 'row--cards'} ${
            [CardModuleView.GRID, CardModuleView.GRID_WIDE, CardModuleView.GRID_FULL].includes(currentBlock?.view)
              ? `row--grid`
              : ``
          } 
          row--${
            [CardModuleView.LIST, CardModuleView.LIST_WIDE, CardModuleView.LIST_FULL].includes(currentBlock?.view)
              ? 'list'
              : currentBlock?.view
          }
          ${!currentBlock?.view?.includes('list') ? 'row--grid-common' : ''}
          gridSize-${currentBlock?.gridSize || 'auto'}
          gridMobileBeaviour-${currentBlock?.gridMobileBehaviour || 'auto'}
          ${currentBlock?.cssClass || ''}
          img-ratio-${currentBlock?.settings?.mediaRatio || 'auto'}
          `}>
          <div
            className={`${currentBlock?.view === CardModuleView.HORIZONTAL ? 'row__viewport' : 'row__grid'}`}
            style={
              currentBlock?.view === CardModuleView.HORIZONTAL ? { overflowX: 'auto', paddingBottom: '20px' } : {}
            }>
            <div className='row__content'>
              {(currentBlock?.structure__search === 'search' ? cardSearchResult.slice(0, rows) : manualCards)?.map(
                (card, index) => {
                  return (
                    <div
                      key={`${card.id}-${index}`} // eslint-disable-line react/no-array-index-key
                      className={`module module--card layout--auto-image ${
                        card.local?.size || currentBlock?.settings?.size
                          ? `module--${currentBlock?.settings?.size || card.local?.size}`
                          : 'module--small'
                      } status--${card.page?.status}`}
                      style={currentBlock.view === CardModuleView.HORIZONTAL ? { float: 'left' } : {}}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>
                        <div
                          className={`module__grid ${
                            (hasCommonSkin ? currentBlock?.skin : false) ||
                            card?.local?.skin ||
                            card?.content?.skin ||
                            card?.skin ||
                            'skin'
                          } skin-card skin-site-${card?.site_id !== selectedSite?.id ? card?.site_id : 'me'}`}>
                          <ImagePreview mediaObject={card.content?.mediaobject || card?.mediaobject || undefined} />
                          <div className='module__content'>
                            <div className='module__head'>
                              <h3 className='card-title hyphenate'>{card.local?.title || card.title}</h3>
                            </div>
                            <div className='module__body'>
                              <p>{card.local?.descr || card.content?.descr}</p>
                              {card.block_type === BlockType.CARD && !!card.content?.callToActionButtonText && (
                                <div className='card-call-to-action module__button'>
                                  {card.content?.callToActionButtonText}
                                </div>
                              )}
                            </div>
                            {card.block_type === BlockType.CARD && (
                              <div className='module__foot'>
                                {currentBlock?.settings?.metadataformat?.includes('CATEGORY') && card.category?.tag && (
                                  <strong className='meta__label meta--category'>{card.category?.tag}</strong>
                                )}

                                {currentBlock?.settings?.metadataformat?.includes('LOCATION') &&
                                  card.content?.location?.title && (
                                    <strong className='meta__label meta--location'>
                                      {card.content?.location?.title}
                                    </strong>
                                  )}

                                {currentBlock?.settings?.metadataformat?.includes('FOOTERLOC') &&
                                  card.content?.footerLocation && (
                                    <strong className='meta__label meta--location'>
                                      {card.content?.footerLocation}
                                    </strong>
                                  )}

                                {currentBlock?.settings?.metadataformat?.includes('EVENTS') &&
                                  !card?.content?.hideDates &&
                                  card.content?.calendar?.events && <div className='meta--date'>-- DATO --</div>}

                                {currentBlock?.settings?.metadataformat?.includes('FOOTERTEXT') &&
                                card?.content?.footerText ? (
                                  <div className='meta--date'> {card?.content?.footerText} </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                },
              )}
            </div>
            {currentBlock?.links && currentBlock?.links.length > 0 && (
              <footer className='row__footer'>
                <div className='article__grid'>
                  <LinksPreview linkItems={currentBlock?.links} linkStyle={LinkStyle.BUTTONS} />
                </div>
              </footer>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CardModuleBlockPreview;
