import React, { FC, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDebounce } from '@/hooks/useDebounce';
import type { Editor as EditorType } from 'tinymce';
import { WysiwygEditorProps } from '@/editor/PageEditor/EditorBlock/TextBlock/WysiwygEditor';

export const TinyEditor: FC<WysiwygEditorProps> = ({ value, onChange, toolbar }) => {
  const [editorValue, setEditorValue] = useState(value || '');
  useEffect(() => {
    setEditorValue(value || '');
  }, [value]);

  const editorRef = useRef<EditorType | null>(null);
  let cleaned_string;
  useDebounce(400, editorValue, onChange);

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      value={editorValue}
      onEditorChange={(newValue) => {
        setEditorValue(newValue);
      }}
      onPaste={(e, editor) => {
        console.log('onPaste editor:', editor);
        console.log('onPaste e:', e);
        // e.preventDefault();
        // GET AND WASH THE CONTENT: (OR CAN WE USE PASTE_PLUGIN OR PASTE_PREPROSESS?)
        // PUT IT INTO EDITOR:
        // editor.execCommand('mceInsertContent', false, content);
      }}
      init={{
        width: '100%',
        menubar: false,
        contextmenu: false,
        plugins: ['autoresize', 'lists', 'link', 'anchor', 'code'],
        toolbar: toolbar ?? 'blocks | bold italic | bullist numlist | link | removeformat | code | paste',
        content_style: 'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:14px }',
        block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5;',
        max_height: 400,
        min_height: 300,
        extended_valid_elements: 'b',
        paste_preprocess: (editor, e) => {
          console.log('paste_postprocess:e:', e);
          console.log('paste_postprocess:Orginal content:', e.content);
          cleaned_string = e.content;
          // cleaned_string = cleaned_string.replace(/style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, '');
          cleaned_string = cleaned_string.replace(/style=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/class=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/id=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?span[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?section[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?article[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?main[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?nav[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?footer[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?header[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?figure[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?img[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<\/?div[^>]*>/g, '');
          cleaned_string = cleaned_string.replace(/<h1>/g, '<h2>');
          cleaned_string = cleaned_string.replace(/<\/h1>/g, '</h2>');
          cleaned_string = cleaned_string.replace(/<(|\/)(html|body|meta)[^>]*?>/gi, '').trim();
          cleaned_string = cleaned_string.replace(/^\s*(<br\s*\/?\s*>)+|(<br\s*\/?\s*>)+\s*$/gi, '');

          console.log('paste_postprocess:Cleaned up content:', cleaned_string);

          e.content = cleaned_string;
          // editor.execCommand('mceInsertContent', false, cleaned_string);
        },
        formats: {
          bold: { inline: 'b', remove: 'all' },
        },
        paste_block_drop: false,
        paste_merge_formats: true,
        paste_data_images: false,
      }}
    />
  );
};
