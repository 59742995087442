import React, { useEffect, useState } from 'react';
import { Api } from '@/services/Api';
import { FormModule } from '@/declarations/models/FormModule';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { FormBlockModel } from '../../../declarations/models/blocks/FormBlockModel';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';

const labelcss = {
  display: 'inline-block',
  fontSize: '.95rem',
  marginTop: '1.5em',
  marginBottom: '0.5em',
  fontWeight: '600',
};

const InputText = (input: any) => {
  const { label, required, subtype } = input;
  return (
    <div>
      <div style={labelcss}>
        {label} {required && '*'}
      </div>
      <div className='form__input'>
        <input type={subtype} />
      </div>
    </div>
  );
};

const InputTextarea = (input: any) => {
  const { label, required, subtype } = input;
  return (
    <div>
      <div style={labelcss}>
        {label} {required && '*'}
      </div>
      <div className='form__input'>
        <textarea />
      </div>
    </div>
  );
};

const InputCheckbox = (input: any) => {
  const { label, required } = input;
  return (
    <p className='form__input  input--checkbox'>
      <br />
      <input id={label} type='checkbox' />
      <label htmlFor={label}>
        {label} {required && '*'}
      </label>
    </p>
  );
};

const InputContent = (input: any) => {
  const { title, text } = input;
  return (
    <div className='article__bodytext'>
      <h2>{title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: text || '' }} // eslint-disable-line react/no-danger
      />
    </div>
  );
};

const InputButton = (input: any) => {
  const { label } = input;
  return (
    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '1.5em' }} className='module__foot'>
      <button type='button' className='module__button'>
        {label || 'Send'}
      </button>
    </div>
  );
};

const InputNoPreview = (input: any) => {
  const { label, type, subtype } = input;
  return (
    <div>
      <div style={labelcss}>{label || 'Overskrift'}</div>
      <br />
      Ingen preview for {type} {subtype || ''} ennå.
      <br />
    </div>
  );
};

const InputOptions = (input: any) => {
  const { label, subtype, options } = input;
  return (
    <div>
      <div style={labelcss}>{label || 'Overskrift'}</div>
      <div className='form__options'>
        {options.map((option: any, index: any) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={`form__input input--${subtype}`}>
            <input type={subtype} /> <label htmlFor={index}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const InputAddress = (input: any) => {
  const { label, address, required } = input;
  return (
    <div>
      <div style={labelcss}>
        {address.label || label} {required && '*'}
      </div>
      <div className='form__input'>
        <input type='text' />
      </div>
      <div className='form__row'>
        <div className='form__col form__postcode'>
          <div style={labelcss}>Postkode {required && '*'}</div>
          <div className='form__input'>
            <input type='text' />
          </div>
        </div>
        <div className='form__col  form__postoffice'>
          <div style={labelcss}>Sted {required && '*'}</div>
          <div className='form__input'>
            <input type='text' />
          </div>
        </div>
      </div>
    </div>
  );
};

const InputName = (input: any) => {
  const { label, required } = input;
  return (
    <div>
      <div>
        <div style={labelcss}>
          {label || 'Fornavn'} {required && '*'}
        </div>
        <div className='form__input'>
          <input type='text' />
        </div>
      </div>
      <div>
        <div style={labelcss}>Etternavn {required && '*'}</div>
        <div className='form__input'>
          <input type='text' />
        </div>
      </div>
    </div>
  );
};

const InputUser = (input: any) => {
  const { subtype, required } = input;
  return (
    <div>
      <div>
        <div style={labelcss}>Fornavn *</div>
        <div className='form__input'>
          <input type='text' />
        </div>
      </div>
      <div>
        <div style={labelcss}>Etternavn *</div>
        <div className='form__input'>
          <input type='text' />
        </div>
      </div>
      <div>
        <div style={labelcss}>Epost *</div>
        <div className='form__input'>
          <input type='text' />
        </div>
      </div>
      {subtype === 'simple' && (
        <div>
          <div>
            <div style={labelcss}>Telefon {required && '*'}</div>
            <div className='form__input'>
              <input type='text' />
            </div>
          </div>
        </div>
      )}
      {subtype === 'complete' && <div style={labelcss}>Her vil det vises flere kontakt/adresse-felter..</div>}
    </div>
  );
};

const FormInput = (input: any) => {
  const { type, subtype } = input;

  let inputTag = <InputNoPreview {...input} />;

  if (type === 'input') {
    inputTag = <InputText {...input} />;

    if (subtype === 'address') {
      inputTag = <InputAddress {...input} />;
    } else if (subtype === 'name') {
      inputTag = <InputName {...input} />;
    } else if (subtype === 'textarea') {
      inputTag = <InputTextarea {...input} />;
    }
  } else if (type === 'content') {
    inputTag = <InputContent {...input} />;
  } else if (type === 'options') {
    inputTag = <InputOptions {...input} />;
  } else if (type === 'checkbox') {
    inputTag = <InputCheckbox {...input} />;
  } else if (type === 'button') {
    inputTag = <InputButton {...input} />;
  } else if (type === 'user') {
    inputTag = <InputUser {...input} />;
  }

  return <>{inputTag}</>;
};

const FormBody = (formobj: any) => {
  const { content } = formobj;
  return (
    <div className='module__form'>
      {content.sections[0].inputs
        .filter((input: any) => input.hidden !== true)
        ?.map((item: any, index: any) => (
          // eslint-disable-next-line react/no-array-index-key
          <FormInput key={index} {...item} />
        ))}
      <br />
      <br />
    </div>
  );
};

export const FormBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as FormBlockModel;
  const [form, setForm] = useState<FormModule | null>(null);

  useEffect(() => {
    if (currentBlock?.formobject?.id) {
      const ctx = Api.getFormModule(currentBlock?.formobject?.id);

      ctx
        .fetchDirect(null)
        .then((response) => {
          setForm(response);
        })
        .catch((error) => {
          console.error(error);
          setForm(null);
        })
        .finally(ctx.abort);
    } else {
      setForm(null);
    }
  }, [currentBlock?.formobject?.id]);

  return (
    <div className='article__grid'>
      {currentBlock?.module__type === BlockModuleType.INLINE && (
        <div className='article__content layout--auto'>
          <div className='article__bodytext'>
            {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
            <p>{currentBlock?.body}</p>
            <div className='module__body'>{form && <FormBody {...form} />}</div>
          </div>
        </div>
      )}
      {currentBlock?.module__type !== BlockModuleType.INLINE && (
        <div className='module module--card module--content layout--auto'>
          <div className={`module__grid skin-form ${currentBlock?.skin || 'skin'}`}>
            <div className='module__content'>
              <div className='module__head'>
                {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
                <p>{currentBlock?.body}</p>
              </div>
              <div className='module__body'>{form && <FormBody {...form} />}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormBlockPreview;
