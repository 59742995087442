import React from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { PageListManualBlock, PageListViewType } from '../../../../declarations/models/blocks/PageListBlocks';
import PageListBlockPreviewItem from './PageListBlockPreviewItem';

export const PageListManualBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as PageListManualBlock;

  return (
    <div
      className={`pagelist pagelist--${currentBlock.view || 'grid'} pagelist--${currentBlock?.grid33 ? 'grid33' : ''}`}>
      <div className='pagelist__intro'>
        {block?.visibletitle && block?.title && <h2>{block?.title}</h2>}
        {block?.body && <p>{block?.body}</p>}
      </div>
      {(currentBlock?.view === PageListViewType.GRID ||
        currentBlock?.view === PageListViewType.SIMPLE ||
        !currentBlock?.view) && (
        <div className='pagelist__grid'>
          {currentBlock?.items?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PageListBlockPreviewItem key={`${item.id}-${index}`} item={item} block={currentBlock} />
          ))}
        </div>
      )}

      {currentBlock?.view === PageListViewType.LIST && (
        <div className='row  row--cards  row--list'>
          <div className='row__grid'>
            <div className='row__content'>
              {currentBlock?.items?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PageListBlockPreviewItem key={`${item.id}-${index}`} item={item} block={currentBlock} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageListManualBlockPreview;
