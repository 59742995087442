import React, { FC, useEffect, useState } from 'react';
import { Box, CSSObject, Drawer as MuiDrawer, Fade, IconButton, styled, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useStore } from '@/components/store/Store';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Layout from './Layout';
import Header from './Header';
import SiteSelector from './SiteSelector';
import UserAvatarMenu from './UserAvatarMenu';
import MainMenu from './MainMenu';
import Container from './Container';
import { ChildrenProp } from '../declarations/ChildrenProp';

const drawerWidth = '250px';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open ? openedMixin(theme) : closedMixin(theme)),

  '& .MuiDrawer-paper': {
    position: 'static',
    gridArea: 'menu',
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
}));

export const MainLayout: FC<
  ChildrenProp & {
    hideTopNav?: boolean;
  }
> = ({ children, hideTopNav = false }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tAria } = useTranslation('aria');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const {
    state: { selectedSiteData, selectedSiteLanguage },
  } = useStore();
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        width: '100vw',
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        gridTemplateRows: '100vh',
        gridTemplateAreas: `
          "menu mainLayout"
        `,
      }}>
      <Box sx={{ gridArea: 'mainLayout', position: 'relative' }}>
        <Layout
          headerContent={
            !hideTopNav && (
              <Header
                additionalContentRight={
                  <>
                    <SiteSelector />
                    <UserAvatarMenu />
                  </>
                }
                hideMenuButton
              />
            )
          }>
          {children}
        </Layout>
      </Box>
      <Drawer open={menuOpen} variant='permanent'>
        <Container pt={1} pl={2} pr={0.5} right fullWidth>
          <Fade in={menuOpen}>
            <Typography sx={{ flex: 1 }} variant='button'>
              {tCommon('menu')}
            </Typography>
          </Fade>

          <IconButton
            aria-label={tAria('components.MainLayout.ToggleMenu')}
            sx={(theme) => ({ color: theme.palette.text.primary })}
            onClick={() => setMenuOpen((o) => !o)}>
            {menuOpen ? <MaterialSymbol name='menu_open' /> : <MaterialSymbol name='menu' />}
          </IconButton>
        </Container>
        <MainMenu open={menuOpen} toggleMenuOpen={() => setMenuOpen(!menuOpen)} />
      </Drawer>
    </Box>
  );
};

export default MainLayout;
