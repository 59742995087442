import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { DMSortType, SortDirection } from '../SortOption';
import { MediaLicense } from '../MediaLicense';
import { DMObjectType } from '../DMObjectType';
import { BlockLayout } from '../BlockLayout';
import { ViewWidth } from '../../ViewType';

export enum DMViewType {
  GRID = 'grid',
  LIST = 'list',
  MAP = 'map',
}

export interface DMBlock extends BaseBlock {
  type: BlockType.DM_AUTO | BlockType.DM_MANUAL;
  view: DMViewType;
  width: ViewWidth;
  dmLink?: string;
  dmLinkText?: string;
  gridSize?: string;
}

export interface DMAutoBlockModel extends DMBlock {
  type: BlockType.DM_AUTO;
  licenseTypes?: Array<MediaLicense>;
  objectTypes?: Array<DMObjectType>;
  useOwnerCodes?: boolean;
  ownerCodes?: string;
  maxItems?: number;
  paginateMaxItems?: number;
  query?: string;
  schoolGrades?: string;
  schoolSubjects?: string;
  subjects?: string;
  sortBy?: DMSortType;
  order?: SortDirection;
  hasPictures?: boolean;
}

interface DMMuseumData {
  altTitle?: string;
  altDescription?: string;
  wideDisplay?: boolean;
}

export interface DmIdItem {
  uniqueId: string;
}

export interface DMManualBlockModel extends DMBlock {
  type: BlockType.DM_MANUAL;
  dmItems: Array<DmIdItem>;
  museum?: DmIdItem;
  museumData?: DMMuseumData;
  vrItems?: Array<DmIdItem>;
  // TODO: implement dmCollections
}

export interface DMSingleBlockModel extends BaseBlock {
  type: BlockType.DM_SINGLE;
  dmItem?: DmIdItem;
  layout?: BlockLayout;
  selectedImgIndex: number;
}
