export enum SortType {
  TITLE = 'title',
  UPDATED_AT = 'updated_at',
  LAST_UPDATED = 'last_updated',
  CREATED_AT = 'created_at',
  PUBLISHED_AT = 'published_at',
  EVENT_START = 'event_start',
  LAST_NAME = 'last_name',
  FIRST_NAME = 'first_name',
  DEPARTMENT = 'department',
  STATUS = 'status',
  RELEVANCE = 'relevance',
}

export enum DMSortType {
  UPDATED_AT = 'artifact.updatedDate',
  TITLE = 'artifact.ingress.title',
  PUBLISHED_AT = 'artifact.publishedDate',
  RELEVANCE = 'relevance',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortOption = {
  sortBy: SortType;
  order: SortDirection;
};
