import { ResponseType } from '../../declarations/ResponseType';

export type RequestMapper<T, R> = (responseBody: T | null) => R | null;

/**
 * This is an object that is uniquely created for each request, and is the returned value for each API-call.
 * The main purpose of this entity is to be able to abort an ongoing request, witch is an important feature in react.
 *
 */
export class RequestContext<T, R = T> {
  private readonly request: Promise<Response>;

  private readonly controller: AbortController;

  private readonly mapper?: RequestMapper<T, R>;

  constructor(request: Promise<Response>, controller: AbortController, mapper?: RequestMapper<T, R>) {
    this.request = request;
    this.controller = controller;
    this.mapper = mapper;
    this.fetch = this.fetch.bind(this);
    this.fetchDirect = this.fetchDirect.bind(this);
    this.abort = this.abort.bind(this);
  }

  public async fetch(): Promise<ResponseType<R>> {
    try {
      const response: Response = await this.request;
      let content = null;
      if (response.status !== 204) {
        if (response.headers.get('Content-Type')?.includes('application/json')) {
          content = await response.clone().json();
        } else {
          content = (await response.clone().text()) || null;
        }
      }
      if (this.mapper) {
        content = this.mapper(content);
      }
      return response.ok ? [content, null, response.clone()] : [null, content ?? true, response.clone()];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // Fetch was aborted. No error and no content
        return [null, null, e];
      }
      // eslint-disable-next-line no-console
      console.error('Request failed', e);
      return [null, e.message, e];
    }
  }

  public async fetchDirect<D = R>(defaultValue: D): Promise<R | D> {
    const [result, error, source] = await this.fetch();
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error, source);
      return defaultValue;
    }
    return result || defaultValue;
  }

  public abort(): void {
    this.controller.abort();
  }
}
