import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Container from '../../components/Container';
import TextInput from '../../components/forms/TextInput';
import SettingsInputContainer from '../../components/SettingsInputContainer';
import { Site } from '../../declarations/models/Site';

export const LanguageSEOSettings: FC = () => {
  const { t } = useTranslation('components');
  const seoDescription = useWatch<Site, `content.settings.description`>({
    name: 'content.settings.description',
    defaultValue: '',
  });

  return (
    <SettingsInputContainer title={t('Settings.LanguageSettings.SEO.title')} anchor='seo'>
      <Container fullWidth column gap={2}>
        <TextInput
          path='content.settings.keywords'
          defaultValue=''
          label={t('Settings.LanguageSettings.SEO.keywords')}
        />
        <TextInput
          path='content.settings.description'
          defaultValue=''
          multiline
          label={`${t('Settings.LanguageSettings.SEO.description')}: ${seoDescription?.length || 0} / 256`}
          maxLength={256}
        />
      </Container>
    </SettingsInputContainer>
  );
};

export default LanguageSEOSettings;
