import React, { FC } from 'react';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import Container from '@/components/Container';
import SelectInput from '@/components/forms/SelectInput';
import { SortDirection, SortType } from '@/declarations/models/SortOption';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/forms/TextInput';

export const DMSchoolBlockListingSelects: FC<{ blockPath: BlockPath }> = ({ blockPath }) => {
  const { t: tComp } = useTranslation('components');

  return (
    <Container fullWidth mb={2} mt={2}>
      <SelectInput
        options={[SortType.RELEVANCE, SortType.LAST_UPDATED, SortType.PUBLISHED_AT]}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tComp(`DMBlock.OrderBySelector.options.${o}`)}
        path={`${blockPath}.orderBy`}
        defaultValue={SortType.RELEVANCE}
        label={tComp('DMBlock.OrderBySelector.Label')}
        hideNoSelectionOption
      />
      <SelectInput
        options={[SortDirection.ASC, SortDirection.DESC]}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tComp(`PageListAutoBlock.OrderSelector.options.${o}`)}
        path={`${blockPath}.order`}
        label={tComp('PageListAutoBlock.OrderSelector.Label')}
        defaultValue={SortDirection.ASC}
        hideNoSelectionOption
      />
      <TextInput
        type='number'
        path={`${blockPath}.maxSearchResults`}
        defaultValue='25'
        label={tComp('PageListAutoBlock.MaxSearchResultsSelector.Label')}
        size='small'
        min={1}
      />
    </Container>
  );
};
