import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, IconButton } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import MainMenu from '@/components/MainMenu';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import BaseEditor from '../lib/BaseEditor';
import { EditorContext } from '../lib/declarations/EditorContext';
import PageEditorHeader from './PageEditorHeader';
import { Page } from '../../declarations/models/Page';
import { Api } from '../../services/Api';
import PageEditorContent from './PageEditorContent';
import { SaveType } from '../lib/declarations/SaveType';
import { deepCopy } from '../../utils/object';
import { Status } from '../../declarations/models/Status';
import Container from '../../components/Container';
import PreviewWrapper from './previews/PreviewWrapper';
import PageSettings from './PageSettings';
import { useStore } from '../../components/store/Store';
import { useApi } from '../../hooks/useApi';
import { useRecursiveSelectionState } from '../../hooks/useRecursiveSelectionState';
import { PageEditorContext, PageEditorContextValue } from './PageEditorContext';
import { PageEditorFormStateProvider } from './PageEditorFormStateProvider';
import { MediaEditorContext, MediaEditorContextValue } from '../MediaEditor/MediaEditorContext';
import FullScreenModal from '../../components/FullScreenModal';
import MediaEditor from '../MediaEditor/MediaEditor';

export const PageEditor: FC = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();
  const { state } = useStore();
  const navigate = useNavigate();
  const { selectedSiteLanguage } = state;
  const selectedSiteId = state.selectedSite?.id || 0;
  const [pages, , , setPages] = useApi(
    () => Api.getAllPagesForSite(selectedSiteId, { include_category: true }),
    [] as Array<Page>,
  );

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [pageSettingsOpen, setPageSettingsOpen] = useState<boolean>(false);

  const [mediaEditorOpen, setMediaEditorOpen] = useState<boolean>(false);
  const [currentMediaId, setCurrentMediaId] = useState<number>();

  const pagesForSelectedLanguage = useMemo<Array<Page>>(() => {
    return pages.filter((page) => page.locale === selectedSiteLanguage);
  }, [pages, selectedSiteLanguage]);

  const selectionState = useRecursiveSelectionState<Page>(
    pagesForSelectedLanguage,
    (page) => page.id || 0,
    (page) => page.parent_id || null,
    true,
  );

  const pageEditorContextValue = useMemo<PageEditorContextValue>(
    () => ({
      pages: pagesForSelectedLanguage,
      setPages,
      selectionState,
    }),
    [pagesForSelectedLanguage, selectionState, setPages],
  );

  const mediaEditorContextValue = useMemo<MediaEditorContextValue>(
    () => ({
      editorOpen: mediaEditorOpen,
      setEditorOpen: setMediaEditorOpen,
      mediaId: currentMediaId,
      setMediaId: setCurrentMediaId,
    }),
    [currentMediaId, mediaEditorOpen],
  );

  const [, setSearchParams] = useSearchParams();

  const handleSavePage = useCallback(
    async (saveType: SaveType, page: Page): Promise<void | Page> => {
      const pageCopy = deepCopy(page);

      if (pageCopy.main_category === 'event') {
        // Make sure event_data is present
        pageCopy.event_data = pageCopy.event_data ?? {};

        // TODO/HACK: workaround for MV-755: remove v1 start_dates and end_dates
        //  backend breaks if old format is sent in
        //  remove workaround then backend has been fixed
        pageCopy.event_data.start_dates = [];
        pageCopy.event_data.end_dates = [];

        // pull all events up to root level, in v2 they are not displayed in the tree
        if (pageCopy.parent_id) {
          pageCopy.parent_id = null;
        }
      }

      if (pageCopy.published_at === '') {
        // Backendcrash if empty published_at is sent in
        delete pageCopy.published_at;
      }

      let savedPage: Page | null = null;
      if (!pageCopy) {
        enqueueSnackbar(tCommon('error'), { variant: 'error' });
        return;
      }

      // PRE-save
      switch (saveType) {
        case SaveType.SAVE_AND_UNPUBLISH:
          pageCopy.status = Status.DRAFT;
          break;
      }
      if (saveType === SaveType.DELETE_DRAFT) {
        if (
          !(await confirm(tComponents('PageEditor.DeleteDraftConfirm'), {
            renderConfirmButton: (onClick) => (
              <Button onClick={onClick} variant='contained' color='error' startIcon={<MaterialSymbol name='delete' />}>
                {tCommon('delete')}
              </Button>
            ),
          }))
        ) {
          return;
        }
        await Api.deletePageVersion(pageCopy.id!, pageCopy.active_version_id!).fetchDirect(null);
        enqueueSnackbar(tCommon('deleteSuccess'), { variant: 'success' });
        setSearchParams({});
        return;
      }

      if (saveType === SaveType.SAVE_AS_NEW_VERSION) {
        savedPage = await Api.createPageDraft(pageCopy.id!, pageCopy).fetchDirect(null);
        setSearchParams({ versionId: savedPage?.active_version_id?.toString() || '' });
      } else if (saveType === SaveType.SAVE_AND_PUBLISH) {
        pageCopy.status = Status.PUBLISHED;
        savedPage = await Api.publishPageDraft(pageCopy.id!, pageCopy.active_version_id!, pageCopy).fetchDirect(null);
      } else if (saveType === SaveType.SAVE_AND_ARCHIVE) {
        pageCopy.status = Status.ARCHIVED;
        savedPage = await Api.publishPageDraft(pageCopy.id!, pageCopy.active_version_id!, pageCopy).fetchDirect(null);
      } else if (saveType === SaveType.SAVE_AS_NEW_TEMPLATE) {
        savedPage = await Api.savePage(pageCopy.site_id, pageCopy).fetchDirect(null);
        const savedTemplate = await Api.postPageTemplate(pageCopy).fetchDirect(null);

        if (savedTemplate) {
          const navigateToTemplate = await confirm(tComponents('PageEditor.newTemplateConfirmDialog.prompt'), {
            renderConfirmButton: (confirmFn) => (
              <Button variant='contained' color='secondary' onClick={confirmFn}>
                {tComponents('PageEditor.newTemplateConfirmDialog.navigateToTemplate')}
              </Button>
            ),
            renderCancelButton: (cancelFn) => (
              <Button onClick={cancelFn}>{tComponents('PageEditor.newTemplateConfirmDialog.cancel')}</Button>
            ),
          });

          if (navigateToTemplate) {
            return navigate(`/editor/${savedTemplate.id!}`);
          }
        }
      } else if (pageCopy.is_template) {
        savedPage = await Api.putPageTemplate(pageCopy.id!, pageCopy).fetchDirect(null);
      } else {
        savedPage = await Api.savePage(pageCopy.site_id, pageCopy).fetchDirect(null);
      }

      if (!savedPage) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedPage;
      }
    },
    [confirm, enqueueSnackbar, setSearchParams, tCommon, tComponents],
  );
  const handleLoadModel = (pageId: number, versionId?: number) => {
    return Api.getPage(pageId, {
      version_id: versionId,
    });
  };

  const handleCloseMediaEditor = () => {
    setMediaEditorOpen(false);
    setCurrentMediaId(undefined);
  };

  return (
    <PageEditorContext.Provider value={pageEditorContextValue}>
      <MediaEditorContext.Provider value={mediaEditorContextValue}>
        <PageEditorFormStateProvider>
          <BaseEditor<Page>
            context={EditorContext.PAGE}
            header={
              <PageEditorHeader
                menuOpen={menuOpen}
                onToggleMenuOpen={() => setMenuOpen((o) => !o)}
                onTogglePageSettingsOpen={() => setPageSettingsOpen((o) => !o)}
              />
            }
            preview={<PreviewWrapper />}
            onSubmit={handleSavePage}
            loadModel={handleLoadModel}>
            <PageEditorContent />
            <PageSettings open={pageSettingsOpen} onClose={() => setPageSettingsOpen(false)} />
            <Drawer open={menuOpen} onClose={() => setMenuOpen(false)} variant='temporary'>
              <Container column top left fullWidth fullHeight p={1} gap={0}>
                <Container right fullWidth gap={0}>
                  <IconButton color='secondary' onClick={() => setMenuOpen(false)}>
                    <MaterialSymbol name='menu_open' />
                  </IconButton>
                </Container>

                <MainMenu open={menuOpen} toggleMenuOpen={() => setMenuOpen(!menuOpen)} />
              </Container>
            </Drawer>
          </BaseEditor>
        </PageEditorFormStateProvider>
        <FullScreenModal modalOpen={mediaEditorOpen}>
          {!!currentMediaId && <MediaEditor onCloseEditor={handleCloseMediaEditor} resourceId={currentMediaId} />}
        </FullScreenModal>
      </MediaEditorContext.Provider>
    </PageEditorContext.Provider>
  );
};

export default PageEditor;
