import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const DeprecatedBlockPreview: BlockPreviewComponent = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { block, blockType } = useCurrentBlock();

  const blockName = tCommon(`BlockType.${blockType}`);
  return (
    <div
      className='article__grid'
      style={{ padding: '20px', fontSize: '18px', background: '#A4E1FF', opacity: '.85', fontFamily: 'monospace' }}>
      <strong>{blockName}</strong>
      <p>{tComponents('Migration.preview')}</p>
    </div>
  );
};

export default DeprecatedBlockPreview;
