/* istanbul ignore file */

export enum MainCategory {
  NONE = '',
  EVENT = 'event',
  EXHIBITION = 'exhibition',
  // MUSEUM = 'museum',
  INFORMATION = 'information',
  KNOWLEDGE = 'knowledge',
}

export type SubCategory = EventCategory | ExhibitionCategory | InformationCategory | KnowledgeCategory;

export enum EventCategory {
  CONCERT = 'concert',
  LECTURE = 'lecture',
  PERFORMANCE = 'performance',
  TOUR = 'tour',
  MARKET = 'market',
  COURSE = 'course',
  SCHOOL_EVENT = 'school_event',
  EXHIBITION = 'exhibition',
  OPENING = 'opening',
  FAMILY_EVENT = 'family_event',
  ACTIVITY = 'activity',
  FAMILY = 'family',
  ADULTS = 'adults',
  YOUTH = 'youth',
  KIDS = 'kids',
}

export enum ExhibitionCategory {
  PERMANENT = 'permanent',
  TEMPORARY = 'temporary',
  EARLIER = 'earlier',
  UPCOMING = 'upcoming',
  // DIGITAL = 'digital',
}

export enum InformationCategory {
  MUSEUM = 'museum',
  PRESS = 'press',
  OPEN_POSITION = 'open_position',
  CURRENT_AFFAIRS = 'current_affairs',
  RENTAL = 'rental',
  SERVICES = 'services',
}

export enum KnowledgeCategory {
  SCHOOL = 'school',
  RESEARCH = 'research',
  COLLECTION = 'collection',
}

export function getSubCategories(mc: MainCategory): Array<SubCategory> {
  switch (mc) {
    case MainCategory.EVENT:
      return Object.values(EventCategory);
    case MainCategory.EXHIBITION:
      return Object.values(ExhibitionCategory);
    case MainCategory.INFORMATION:
      return Object.values(InformationCategory);
    case MainCategory.KNOWLEDGE:
      return Object.values(KnowledgeCategory);
    default:
      return [];
  }
}
