import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { EmployeesBlock, EmployeesViewType } from '../../../declarations/models/blocks/EmployeesBlock';
import { Employee } from '../../../declarations/models/Employee';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import { Site } from '../../../declarations/models/Site';
import { calculateObjectPosition } from '../../../components/Image';

const getViewClass = (viewType?: string) => {
  switch (viewType) {
    case EmployeesViewType.LIST:
      return 'employees--list';
    case EmployeesViewType.GRID:
      return 'employees--grid';
    default:
      return '';
  }
};

export const EmployeesBlockPreview: BlockPreviewComponent = () => {
  const { t } = useTranslation('common');
  const { state } = useStore();
  const siteId = state.selectedSite?.id || null;
  const selectedSiteLanguage = state?.selectedSiteLanguage || 'no';
  const { block } = useCurrentBlock();
  const currentBlock = block as EmployeesBlock;

  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [sites, setSites] = useState<Array<Site>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (siteId) {
      const itemIds = currentBlock.items ? currentBlock.items.map((i) => i.id).join(',') : undefined;
      const siteIds = currentBlock.items ? undefined : currentBlock.siteIds;

      if (!itemIds && !siteIds) setEmployees([]);
      else {
        const ctx = Api.getEmployees(siteId, {
          employee_ids: itemIds,
          site_ids: siteIds,
          text_query: searchQuery,
          order_by: currentBlock.sortBy,
          order: currentBlock.order,
          locale: selectedSiteLanguage,
        });

        ctx
          .fetchDirect(null)
          .then((res) => !!res && setEmployees(res.result))
          .finally(ctx.abort);
      }
    }
  }, [currentBlock.siteIds, currentBlock.items, searchQuery, siteId, currentBlock.sortBy, currentBlock.order]);

  useEffect(() => {
    const siteIds =
      currentBlock.siteIds
        ?.split(',')
        .map((id) => Number(id))
        .filter((id) => !!id) || [];
    if (siteIds.length) {
      const sitesTemp: Array<Site> = [];
      Promise.all(
        siteIds.map(async (id) => {
          await Api.getSite(id)
            .fetchDirect(null)
            .then((site) => !!site && sitesTemp.push(site));
        }),
      ).finally(() => setSites(sitesTemp));
    }
  }, [siteId, currentBlock.siteIds]);

  return (
    <div className={`employees ${getViewClass(currentBlock.view)}`} data-module='employees__manual/auto'>
      <div className='employees__intro'>
        <div className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          <p>{currentBlock?.body}</p>
        </div>
      </div>
      {currentBlock.enableSearch && (
        <div className='employees__search' style={{ marginBottom: '20px' }}>
          <input
            type='text'
            placeholder={t('search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      )}
      <div className='employees__grid'>
        {employees.map((item) => (
          <div
            key={item.id}
            className={`employee skin-employee ${currentBlock?.skin || ''} skin-site-${
              item?.site_id !== siteId ? item?.site_id ?? item?.site_id : 'me'
            } `}>
            <div className='employee__media'>
              {item.resource?.url ? (
                <img
                  style={{
                    objectPosition: calculateObjectPosition(item?.image_focus_point?.x, item?.image_focus_point?.y),
                  }}
                  src={`${item.resource?.url}?dimension=600x600${
                    item?.resource?.mimetype?.includes('png') ? '&mediaType=image/png' : ''
                  }`}
                  alt={item.resource?.content?.alttext}
                  loading='lazy'
                />
              ) : null}
            </div>
            <div className='employee__content'>
              <div className='employee__header'>
                <h3>{`${item.first_name} ${item.last_name}`}</h3>
                <p className='employee__meta'>
                  {currentBlock.showPosition && item.position && <span>{item.position}</span>}
                </p>
              </div>
              <div className='employee__body'>
                {currentBlock.showDescription && item.description && (
                  <p className='employee__description'>{item.description}</p>
                )}

                <ul className='employee__linklist'>
                  {currentBlock.showEmail && item.email_primary && <li>{item.email_primary}</li>}
                  {currentBlock.showEmail && item.email_secondary && <li>{item.email_secondary}</li>}
                  {currentBlock.showPhone && item.phone_primary && <li>{item.phone_primary}</li>}
                  {currentBlock.showPhone && item.phone_secondary && <li>{item.phone_secondary}</li>}
                  {currentBlock.showLinks && item.linked_page && <li>{item.linked_page.title}</li>}
                  {currentBlock.showLinks &&
                    item.link_list?.map((l, i) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid,react/no-array-index-key
                      <li key={`${i}-${l.link_name}`}>{l.link_name}</li>
                    ))}
                </ul>
              </div>
              <div className='employee__footer'>
                <div className='employee__museum'>
                  {currentBlock.showSite && (sites.find((s) => s.id === item.site_id)?.name || 'Museum')}
                </div>
                <div className='employee__department'>{currentBlock.showDepartment && (item.department || '')}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeesBlockPreview;
